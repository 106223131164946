/* eslint-disable jsx-a11y/click-events-have-key-events */
/* global om */
import { verifyOm } from '~/utils/utils';

/**
 * Link Tracker
 * @category Functional Component
 * @summary Links content with Omniture tracking.
 * @return {Node} React node containing the link tracking.
 */

const LinkTracker = props => {
  const {
    children,
    destinationUrl,
    linkCta,
    linkFormat,
    linkRegion,
    leadStore='',
    linkSubRegion
  } = props;

  const data = {
    destinationUrl,
    linkCta,
    linkFormat,
    linkRegion,
    leadStore,
    linkSubRegion
  };

  const fireOm = async () => {
    const isAnalyticsVerified = await verifyOm(100, 10);
    if (isAnalyticsVerified) {
      om.trackLink(data);
    }
  }

  return (
    <div role='button' onClick={ fireOm }>
      { children }
    </div>
  );
}

export default LinkTracker;
